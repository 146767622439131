import React from 'react';
import { Col, Divider, Grid, Row } from '@components/Grid';
import { graphql } from 'gatsby';
import styles from './styles.module.scss';
import Layout from '@components/Layout';
import PortfolioCard from '@components/PortfolioCard';
import Hero from '@components/Hero';
import SEO from '@components/Seo';
import ServiceCard from '@components/ServiceCard';

const Service = ({
    data: {
        page: {
            frontmatter: { name, description, code, path },
        },
        servicesCategories: { edges: servicesCategories },
        portfolio: { edges: portfolio },
    },
}) => {
    return (
        <Layout>
            <SEO title={`${name} • Services`} />
            <Hero title={name} description={description} />

            <div className={styles.serviceWrapper}>
                <Divider margin={5} />
                <Divider xsMargin={0} smMargin={5} />

                <section>
                    <Grid>
                        <Row>
                            <Col xs={12}>
                                <h2>{`${name} Services`}</h2>
                            </Col>
                        </Row>

                        <Divider margin={3} />

                        <div className={styles.serviceCardWrapper}>
                            <Row>
                                {servicesCategories.map(
                                    ({ node: { frontmatter: data } }, index) => (
                                        <Col xs={12} sm={12} md={6} lg={6} xl={4} key={index}>
                                            <ServiceCard data={data} arrow />
                                        </Col>
                                    )
                                )}
                            </Row>
                        </div>
                    </Grid>
                </section>

                <section className={styles.portfolioWrapper}>
                    <Divider margin={5} />
                    <Divider xsMargin={0} smMargin={5} />

                    <Grid>
                        <div className={styles.portfolioCardContainer}>
                            <Row>
                                {portfolio?.map((data) => (
                                    <Col xs={12} key={data?.node?.frontmatter?.heading}>
                                        <div className={styles.portfolioCardWrapper}>
                                            <PortfolioCard data={data} />
                                        </div>
                                    </Col>
                                ))}
                            </Row>
                        </div>
                    </Grid>

                    <Divider margin={5} />
                    <Divider xsMargin={0} smMargin={5} />
                </section>
            </div>
        </Layout>
    );
};

export default Service;

export const query = graphql`
    query($path: String, $code: String) {
        page: markdownRemark(frontmatter: { path: { eq: $path } }) {
            frontmatter {
                path
                name: services_service_heading
                description: services_service_description
                code: services_service_code
                image: services_service_image {
                    childImageSharp {
                        fluid(maxWidth: 1920) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        }
        servicesCategories: allMarkdownRemark(
            filter: {
                frontmatter: { services_service_items: { in: [$code] } }
                fileAbsolutePath: { regex: "/servicesCategories/" }
            }
        ) {
            edges {
                node {
                    frontmatter {
                        path
                        heading: services_service_heading
                        image: services_service_image {
                            childImageSharp {
                                fluid(maxWidth: 800) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                        description: services_service_card_description
                    }
                }
            }
        }
        portfolio: allMarkdownRemark(
            filter: {
                frontmatter: { portfolio_item_services: { in: [$code] } }
                fileAbsolutePath: { regex: "/case-studies/" }
            }
        ) {
            edges {
                node {
                    frontmatter {
                        path
                        heading: portfolio_item_title
                        subHeading: portfolio_item_sub_heading
                        desktopImage: portfolio_item_desktop_image {
                            childImageSharp {
                                fluid(maxWidth: 550, maxHeight: 300) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                        bgImage: portfolio_item_bg {
                            childImageSharp {
                                fluid(maxWidth: 650, maxHeight: 350) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                        description: portfolio_item_description
                        date: portfolio_item_date
                        read_time: portfolio_item_readtime
                        services: portfolio_item_services
                        servicesCategories: portfolio_item_services_categories
                        favourite: portfolio_item_favourite
                        displayedServices: portfolio_item_displayed_services {
                            path: portfolio_item_displayed_services_link
                            name: portfolio_item_displayed_services_name
                        }
                    }
                }
            }
        }
    }
`;
